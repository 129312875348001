.paint-container {
  display: flex;
}

.paint-container ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 1.5em 0 0;
}

.paint-container li {
  list-style: none;
}

.paint-bucket {
  margin: 0 0.2em;
  width: 3.5em;
  height: 3.5em;
  outline: none;
  border: none;
  border: 1px solid #d6d6d6;
  border-radius: 15px;
  pointer-events: all;
  cursor: pointer;
}

.active {
  box-shadow: 0 0 0 1px #787878;
}
