@import '../../utils/variables.scss';

.container {
  position: absolute;
  top: 0;
  left: 50px;
  z-index: 15;
  pointer-events: none;
  font-family: $mainFont;
  word-spacing: -0.3em;
}

h1 {
  font-size: 3.5rem;
}

p {
  font-size: 1.5rem;
  line-height: 150%;
}

.links a {
  position: relative;
  font-size: 1.1rem;
  list-style: none;
  text-decoration: none;
  margin-right: 1em;
  color: $black;
  pointer-events: all;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px; /* Set the height of the underline, adjust as needed */
    background-color: $black; /* Change this to the color you want for the underline */
    transition: width 0.3s ease; /* Set the animation duration and timing function */
  }

  &:hover::before {
    width: 100%;
  }
}
