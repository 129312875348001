.menu-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: 2em;
  width: 100%;
  height: 5em;
  padding: 0 10em 0 10em;
  pointer-events: none;
  box-sizing: border-box;
}

.menu-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;
  padding: 0 1.5em;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 3.12708px 0px,
    rgba(0, 0, 0, 0.17) 0px 0px 0.931014px 0px;
  box-sizing: border-box;
}

button {
  background-color: transparent;
  border: none;
  pointer-events: all;
  cursor: pointer;
  margin: 0 0.25em;
}

button svg {
  width: 2em;
  height: 2em !important;
}
